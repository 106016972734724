import BlueimpGallery from 'blueimp-gallery';
import $ from 'jquery';

export default function enableGallery() {

    if ($('#links').length) {
        //AUTOSTART
        // let links = document.getElementById('links').getElementsByTagName('a');
        // let options = {
        //     container: '#blueimp-gallery',
        //     startSlideshow: false,
        //     carousel: false,
        // }
        // new BlueimpGallery(links, options);

        document.getElementById('links').onclick = function (event) {
            let localEvent = event || window.event;
            let target = localEvent.target || localEvent.srcElement;
            let link = target.src ? target.parentNode : target;
            let options = {
                index: link,
                event: localEvent
            };
            let links = this.getElementsByTagName('a');
            new BlueimpGallery(links, options);

        };
    }
}