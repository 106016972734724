import 'bootstrap';
import enableGallery from "./js/gallery";
// Assets
import './scss/index.scss';

import './js/ux.js';

$(document).ready(function () {
    enableGallery();
});

